import {Component, OnInit} from '@angular/core';
import {LoadingService} from '../../shared/services/loading.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  styleUrls: ['./error-dialog.component.scss'],
  templateUrl: './error-dialog.component.html'
})

export class ErrorDialogComponent implements OnInit {
  constructor(private loadingService: LoadingService,
              private matDialogRef: MatDialogRef<ErrorDialogComponent>) {
    this.loadingService.hide();
  }

  public ngOnInit(): void {
    this.matDialogRef.updateSize('600px');

    this.matDialogRef.disableClose = true;
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }
}
