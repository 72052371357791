import {Component} from '@angular/core';
import {LoadingService} from '../../shared/services/loading.service';

@Component({
  styleUrls: ['./not-found-page.component.scss'],
  templateUrl: './not-found-page.component.html'
})

export class NotFoundPageComponent {
  constructor(private loadingService: LoadingService) {
    this.loadingService.hide();
  }
}
