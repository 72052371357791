<div class="container">
  <div class="left-section">
    <div class="logo">
      <img src="../../../assets/images/Logo.png">
    </div>

    <div class="not-found-section">
      <mat-icon>report</mat-icon>

      <h3>Δεν υπάρχει η σελίδα που αναζητήσατε.</h3>
    </div>
  </div>

  <div class="right-section"></div>
</div>
