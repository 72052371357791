import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loading',
  styleUrls: ['./ngx-loading.component.scss'],
  templateUrl: 'ngx-loading.component.html'
})

export class LoadingComponent {
  @Input() show: boolean | undefined;

  public ANIMATION_TYPES = {
    chasingDots: 'chasing-dots',
    circle: 'sk-circle',
    circleSwish: 'circleSwish',
    cubeGrid: 'sk-cube-grid',
    doubleBounce: 'double-bounce',
    pulse: 'pulse',
    rectangleBounce: 'rectangle-bounce',
    rotatingPlane: 'rotating-plane',
    threeBounce: 'three-bounce',
    wanderingCubes: 'wandering-cubes'
  };

  public loadingConfig: any = {
    animationType: this.ANIMATION_TYPES.threeBounce,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.5)',
    backdropBorderRadius: '0px',
    fullScreenBackdrop: true,
    primaryColour: 'white',
    secondaryColour: 'white',
    tertiaryColour: 'white'
  };

  constructor() {
  }

  public getAnimationType(animationType: string): string {
    let animationTypeSet: string;

    switch (animationType) {
      case this.ANIMATION_TYPES.chasingDots:
        animationTypeSet = this.ANIMATION_TYPES.chasingDots;
        break;
      case this.ANIMATION_TYPES.circle:
        animationTypeSet = this.ANIMATION_TYPES.circle;
        break;
      case this.ANIMATION_TYPES.circleSwish:
        animationTypeSet = this.ANIMATION_TYPES.circleSwish;
        break;
      case this.ANIMATION_TYPES.cubeGrid:
        animationTypeSet = this.ANIMATION_TYPES.cubeGrid;
        break;
      case this.ANIMATION_TYPES.doubleBounce:
        animationTypeSet = this.ANIMATION_TYPES.doubleBounce;
        break;
      case this.ANIMATION_TYPES.pulse:
        animationTypeSet = this.ANIMATION_TYPES.pulse;
        break;
      case this.ANIMATION_TYPES.rectangleBounce:
        animationTypeSet = this.ANIMATION_TYPES.rectangleBounce;
        break;
      case this.ANIMATION_TYPES.rotatingPlane:
        animationTypeSet = this.ANIMATION_TYPES.rotatingPlane;
        break;
      case this.ANIMATION_TYPES.threeBounce:
        animationTypeSet = this.ANIMATION_TYPES.threeBounce;
        break;
      case this.ANIMATION_TYPES.wanderingCubes:
        animationTypeSet = this.ANIMATION_TYPES.wanderingCubes;
        break;
      default:
        animationTypeSet = this.ANIMATION_TYPES.threeBounce;
    }

    return animationTypeSet;
  }
}
