import {environment} from '../../environments/environment';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {ErrorHandler, Injectable} from '@angular/core';
import {LoadingService} from '../shared/services/loading.service';
import {MatDialog} from '@angular/material/dialog';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://0eb158d315134c5d8cdd01ced10b514f@o505659.ingest.sentry.io/5628315'
});

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private loadingService: LoadingService,
              private matDialog: MatDialog) {
  }

  handleError(error: any): void {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.log(error);
    }

    this.loadingService.hide();

    this.matDialog.open(ErrorDialogComponent, {});
  }
}
