import {AngularFireModule} from '@angular/fire';
import {AppComponent} from './app.component';
import {AppErrorHandler} from './errors/app-error-handler';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorDialogComponent} from './errors/error-dialog/error-dialog.component';
import {ErrorHandler, NgModule} from '@angular/core';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {LoadingComponent} from './shared/ngx-loading/ngx-loading.component';
import {MaterialModule} from './shared/material.module';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';

export const firebaseConfig = {
  apiKey: 'AIzaSyBB2Us7XugkhyMLxIwtj3i2wZkKnObNWV0',
  authDomain: 'letter-guarantee-checking.firebaseapp.com',
  databaseURL: 'https://letter-guarantee-checking.firebaseio.com',
  messagingSenderId: '1027784031795',
  projectId: 'letter-guarantee-checking',
  storageBucket: 'letter-guarantee-checking.appspot.com'
};

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    LoadingComponent,
    NotFoundPageComponent
  ],
  entryComponents: [ErrorDialogComponent],
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MaterialModule
  ],
  providers: [
    {
      provide: ErrorHandler, useClass: AppErrorHandler
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    }
  ]
})

export class AppModule {
}
