import {NgModule} from '@angular/core';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),
    path: ''
  },
  {
    component: NotFoundPageComponent,
    path: '**'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
